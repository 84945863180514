import styles from './googleLog.js'
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { func } from 'prop-types';
import queryString from "query-string";
import jwt_decode from "jwt-decode"
import { useDispatch,useSelector} from 'react-redux';
import { authActions } from "../../../store/authSlice"

function GoogleLog(){
    const dispatch = useDispatch()
    let location = useLocation();
    const [error, setError] = useState("");
    const navigate = useNavigate();

    useEffect(() => {
        const values = queryString.parse(location.search);
        const code = values.code ? values.code : null;
    
        if (code) {
        //   onGogglelogin();
        googleLoginHandler(location.search)
        }
      }, []);

    async function googleLoginHandler(code)  {
         await axios
          .get(`${process.env.REACT_APP_DOMAIN}/api/login/google/${code}`)
          .then((response) => {
            dispatch(authActions.setEmail(jwt_decode(response.data.access).email))
            dispatch(authActions.setAdmin(jwt_decode(response.data.access).isAdmin))
            dispatch(authActions.setCoins(jwt_decode(response.data.access).tokens))
            dispatch(authActions.setAccess(response.data.access))
            dispatch(authActions.setLoged(true))
            dispatch(authActions.setReload(response.data.refresh))
            dispatch(authActions.setIsSub(jwt_decode(response.data.access).is_sub))
            dispatch(authActions.setIsTestAccount(jwt_decode(response.data.access).is_test_account))
            dispatch(authActions.setTrial(jwt_decode(response.data.access).trial_check))
            dispatch(authActions.setIsConfirmed(jwt_decode(response.data.access).is_confirmed))
 
            localStorage.setItem('authTokens', JSON.stringify(response.data))
            navigate("/")
          })
          .catch((err) => {
            setError(err);
            navigate("/login")
            console.log(err)
            return err;
          });
      };

    // const onGogglelogin = async () => {
    //     const response = await googleLoginHandler(location.search);
    //     if (response.data.access) {
    //       navigate("/");
    //     }
    //   }
    
    return(
        <div>waiting for response</div>
    )
}
export default GoogleLog