import { components } from "react-select";
import Select from "react-select";
import { useSelector, useDispatch } from "react-redux";
import { EditLabsFilterActions } from "../../../../../store/editLabFilterSlice";
import { addActions } from "../../../../../store/addSlice";
import { useEffect } from "react";
import styles from "./dropdown_F.module.css";
import { MultiSelect } from "primereact/multiselect";
import { useEffetc, useState } from "react";
function DropdownOrgan(props) {
	const dispatch = useDispatch();

	const [selectedCities, setSelectedCities] = useState();
	const name = props.name;
	const [isStart, setIsStart] = useState(0);
	let dataSes = [];

	if (name == "organ") {
		dataSes = JSON.parse(localStorage.getItem("editOrganLabs"));
	}
	if (name == "test") {
		dataSes = JSON.parse(localStorage.getItem("editTestLabs"));
	}
	if (name == "regulations") {
		dataSes = JSON.parse(localStorage.getItem("editRegulationsLabs"));
	}
	if (name == "stage") {
		dataSes = JSON.parse(localStorage.getItem("editStageLabs"));
	}
	if (name == "endpoints") {
		dataSes = JSON.parse(localStorage.getItem("editEndpointsLabs"));
		console.log(dataSes)
	}
	if (name == "aops") {
		dataSes = JSON.parse(localStorage.getItem("editAopsLabs"));
		console.log(dataSes)
	}

	if (name == "type") {
		dataSes = JSON.parse(localStorage.getItem("editTypeLabs"));
	}
	if (name == "used") {
		dataSes = JSON.parse(localStorage.getItem("editUsedLabs"));
	}

	if (name == "name") {
		dataSes = JSON.parse(localStorage.getItem("editNameLabs"));
	}

	const handleChangeTest = (selected) => {
		setSelectedCities(selected.value);

		if (props.name === "organ") {
			if (selected.length != 0) {
				dispatch(EditLabsFilterActions.setOrgans(selected.value));
				localStorage.setItem(
					"editOrganLabs",
					JSON.stringify(selected.value)
				);
			} else {
				dispatch(EditLabsFilterActions.setOrgans());
				localStorage.removeItem("editOrganLabs");
			}
		}

		if (props.name === "test") {
			if (selected.length != 0) {
				dispatch(EditLabsFilterActions.setTests(selected.value));
				localStorage.setItem(
					"editTestLabs",
					JSON.stringify(selected.value)
				);
			} else {
				dispatch(EditLabsFilterActions.setTests());
				localStorage.removeItem("editTestLabs");
			}
		}

		if (props.name === "regulations") {
			if (selected.length != 0) {
				dispatch(EditLabsFilterActions.setRegulations(selected.value));
				localStorage.setItem(
					"editRegulationsLabs",
					JSON.stringify(selected.value)
				);
			} else {
				dispatch(EditLabsFilterActions.setRegulations());
				localStorage.removeItem("editRegulationsLabs");
			}
		}

		if (props.name === "stage") {
			if (selected.length != 0) {
				dispatch(EditLabsFilterActions.setStages(selected.value));
				localStorage.setItem(
					"editStageLabs",
					JSON.stringify(selected.value)
				);
			} else {
				dispatch(EditLabsFilterActions.setStages());
				localStorage.removeItem("editStageLabs");
			}
		}

		if (props.name === "endpoints") {
			if (selected.length != 0) {
				dispatch(EditLabsFilterActions.setEndpoints(selected.value));
				localStorage.setItem(
					"editEndpointsLabs",
					JSON.stringify(selected.value)
				);
			} else {
				dispatch(EditLabsFilterActions.setEndpoints());
				localStorage.removeItem("editEndpointsLabs");
			}
		}

		if (props.name === "aops") {
			if (selected.length != 0) {
				dispatch(EditLabsFilterActions.setAops(selected.value));
				localStorage.setItem(
					"editAopsLabs",
					JSON.stringify(selected.value)
				);
			} else {
				dispatch(EditLabsFilterActions.setAops());
				localStorage.removeItem("editAopsLabs");
			}
		}

		if (props.name === "type") {
			if (selected.length != 0) {
				dispatch(EditLabsFilterActions.setNamType(selected.value));
				localStorage.setItem(
					"editTypeLabs",
					JSON.stringify(selected.value)
				);
			} else {
				dispatch(EditLabsFilterActions.setNamType());
				localStorage.removeItem("editTypeLabs");
			}
		}

		if (props.name === "used") {
			if (selected.length != 0) {
				dispatch(EditLabsFilterActions.setUsed(selected.value));
				localStorage.setItem(
					"editUsedLabs",
					JSON.stringify(selected.value)
				);
			} else {
				dispatch(EditLabsFilterActions.setUsed());
				localStorage.removeItem("editUsedLabs");
			}
		}

		if (props.name === "name") {
			if (selected.length != 0) {
				dispatch(EditLabsFilterActions.setName(selected.value));
				localStorage.setItem(
					"editNameLabs",
					JSON.stringify(selected.value)
				);
			} else {
				dispatch(EditLabsFilterActions.setName());
				localStorage.removeItem("editNameLabs");
			}
		}
	};

	useEffect(() => {}, [props.data]);

	return (
		<div>
			{isStart == 0 && (
				<div>
					<MultiSelect
						value={dataSes}
						onChange={handleChangeTest}
						options={[
							...new Set(
								dataSes
									.concat(props.data)
									.map((item) => item.name)
							),
						]
							.sort()
							.map((item) => ({ name: item, code: item }))}
						optionLabel="name"
						display="chip"
						placeholder="Select"
						className={styles.mypanel}
						filter
					/>
				</div>
			)}
			{isStart == 1 && (
				<div>
					<MultiSelect
						value={selectedCities}
						onChange={handleChangeTest}
						options={[
							...new Set(
								dataSes
									.concat(props.data)
									.map((item) => item.name)
							),
						].map((item) => ({ name: item, code: item }))}
						optionLabel="name"
						display="chip"
						placeholder="Select"
						className={styles.mypanel}
					/>
				</div>
			)}
		</div>
	);
}
export default DropdownOrgan;
