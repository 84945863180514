import { useState, useEffect } from "react";
import styles from "./userStatus.module.css";
import { FaCheck } from "react-icons/fa";
import { FaXmark } from "react-icons/fa6";
import { useSelector } from "react-redux";
import { LiaUserCogSolid } from "react-icons/lia";
import { LiaUserEditSolid } from "react-icons/lia";
import { LiaUser } from "react-icons/lia";

import axios from "axios";
function UserStatus(props) {
	const [change, setChange] = useState(false);
	const authToken = useSelector((state) => state.auth.access);
	let data = JSON.stringify({
		email: props.email,
		roles: [
			props.isAdmin && "is_admin",
			props.isModerator && "is_moderator",
			props.isAmbasador && "is_ambasador",
		],
	});
	async function changeRoles() {
		const requestList = await axios
			.put(`${process.env.REACT_APP_DOMAIN}/api/change_role`, data, {
				headers: {
					"Content-Type": "application/json",
					Authorization: "Bearer " + String(authToken),
				},
			})

			.then(function (response) {
				setChange(false);
			})
			.catch(function (error) {})
			.finally(function () {});
		return;
	}

	return (
		<div className={styles.mainBlock}>
			<div className={styles.iconRow}>
				{props.isAdmin && (
					<div className={styles.userType}>
						<div className={`${styles.adminBlock} ${styles.block}`}>
							<LiaUserCogSolid className={styles.icon} />
						</div>
						<div className={styles.adminLabel}>Admin</div>
					</div>
				)}
				{props.isModerator && (
					<div className={styles.userType}>
						<div
							className={`${styles.moderatorBlock} ${styles.block}`}
						>
							<LiaUserEditSolid className={styles.icon} />
						</div>
						<div className={styles.moderatorLabel}>Moderator </div>
					</div>
				)}
				{props.isAmbasador && (
					<div className={styles.userType}>
						<div
							className={`${styles.ambasadorBlock} ${styles.block}`}
						>
							{" "}
							<LiaUser className={styles.icon} />
						</div>
						<div className={styles.ambasadorLabel}>Ambasador</div>
					</div>
				)}
			</div>
			<div className={styles.status}>
				<div className={styles.statusList}>
					<div className={styles.statusLine}>
						<div>
							{props.isSub ? (
								<div className={styles.checkMark} />
							) : (
								<div className={styles.exMark} />
							)}
						</div>
						<div className={styles.label}>Subscribes</div>
						
					</div>

					<div className={styles.statusLine}>
						<div>
							{props.isActiveSub ? (
								<div className={styles.checkMark} />
							) : (
								<div className={styles.exMark} />
							)}
						</div>
						<div className={styles.label}>Active Subscribtion</div>
						
					</div>

					<div className={styles.statusLine}>
						<div>
							{props.confirmed ? (
								<div className={styles.checkMark} />
							) : (
								<div className={styles.exMark} />
							)}
						</div>
						<div className={styles.label}>Confirmed Account</div>
						
					</div>

					<div className={styles.statusLine}>
						<div>
							{props.trial ? (
								<div className={styles.checkMark} />
							) : (
								<div className={styles.exMark} />
							)}
						</div>
						<div className={styles.label}>Trial Account</div>
						
					</div>

					<div className={styles.statusLine}>
						<div className={styles.tokens}>{props.tokens}</div>
						<div className={styles.label}>Tokens</div>
						
					</div>

					<div className={styles.roles}>
						<div className={styles.statusLine}>
							<div>
							{change ? (
								<input
									type="checkbox"
									onClick={(e) =>
										props.setIsAdmin(!props.isAdmin)
									}
									className={
										props.isAdmin
											? `${styles.check} ${styles.checkMark}`
											: styles.check
									}
								/>
							) : (
								<input
									type="checkbox"
									className={
										props.isAdmin
											? `${styles.check} ${styles.checkMarkDisabled}`
											: styles.check
									}
								/>
							)}
							</div>
							<div className={styles.label}>Admin</div>
							
						</div>
						<div className={styles.statusLine}>
							<div>
							{change ? (
								<input
									type="checkbox"
									onClick={(e) =>
										props.setIsModerator(!props.isModerator)
									}
									className={
										props.isModerator
											? `${styles.check} ${styles.checkMark}`
											: styles.check
									}
								/>
							) : (
								<input
									type="checkbox"
									className={
										props.isModerator
											? `${styles.check} ${styles.checkMarkDisabled}`
											: styles.check
									}
								/>
							)}
							</div>
							<div className={styles.label}>
								Moderator{" "}
							</div>
							
						</div>

						<div className={styles.statusLine}>
							<div>
							{change ? (
								<input
									type="checkbox"
									onClick={(e) =>
										props.setIsAmbasador(!props.isAmbasador)
									}
									className={
										props.isAmbasador
											? `${styles.check} ${styles.checkMark}`
											: styles.check
									}
								/>
							) : (
								<input
									type="checkbox"
									className={
										props.isAmbasador
											? `${styles.check} ${styles.checkMarkDisabled}`
											: styles.check
									}
								/>
							)}
							</div>
							<div className={styles.label}>
								Ambasador
							</div>
							
						</div>
					</div>
				</div>

				<div className={styles.buttonRow}>
					<button
						className={`${styles.button} ${styles.activeChange}`}
						onClick={(e) => setChange(!change)}
					>
						Change
					</button>
					{!change ? (
						<button className={`${styles.button} `}>Apply</button>
					) : (
						<button
							className={`${styles.button} ${styles.activeApply}`}
							onClick={changeRoles}
						>
							Apply
						</button>
					)}
				</div>
			</div>
		</div>
	);
}

export default UserStatus;
