import styles from "./pricing.module.css";
import blob from "../landing/blob3.png"
import { FaCheck } from "react-icons/fa";
function Pricing() {
	return (
		<div className={styles.block}>
            <img src={blob} className={styles.blob1}></img>
            <img src={blob} className={styles.blob2}></img>
			<div className={styles.title}>Pricing</div>
			<div className={styles.colorLine}></div>
			<div className={styles.main}>
				<div className={styles.section}>
					<div className={styles.subtitle}>Try NAMs.network...</div>
					<div className={`${styles.subSubtitle}`}>5 view tokens</div>
					<div className={styles.colorLine}></div>
					
					<div className={styles.txtList}>
						<div className={styles.txtListEl}>
							<FaCheck className={styles.checker}/>
							<div className={styles.text}>
								<div className={styles.textLine}>Register to the database and </div>
								<div className={styles.textLine}>use your free view tokens</div>
								<div className={styles.textLine}>when you like!</div>  
							</div>
					
						</div>
						<div className={styles.txtListEl}>
							<FaCheck className={styles.checker}/>
							<div className={styles.text}>
								<div className={styles.textLine}>Stay up to date with the latest</div>
								<div className={styles.textLine}>information about NAMs</div>
						
							</div>
					
						</div>
						
					</div>
				</div>
                <div className={styles.breakLine}></div>
				<div className={styles.section}>
					<div className={styles.subtitle}>... and stay longer !</div>
					<div className={styles.subSubtitle}>19.99 € / month </div>
					<div className={styles.colorLine}></div>
					<div className={styles.line}></div>
					<div className={styles.txtList}>
						<div className={styles.txtListEl}>
							<FaCheck className={styles.checker}/>
							<div className={styles.text}>
								<div className={styles.textLine}>Unlimited access to nams.network</div>
								<div className={styles.textLine}>resources</div>
							</div>
						</div>
						<div className={styles.txtListEl}>
							<FaCheck className={styles.checker}/>
								<div className={styles.text}>
									<div className={styles.textLine}>Regular updates to the database</div>
									<div className={styles.textLine}>contents: new NAMs, AOPs & NAM</div>
									<div className={styles.textLine}>status verification</div>
								</div>
						</div>
						
					</div>
				</div>
			</div>
		</div>
	);
}
export default Pricing;
