import styles from './widget.module.css'
function Widget(props){
    const strNumb=String(props.number).split('')
    const firstStr=strNumb[0]
    const rest=strNumb.slice(1,)
    return(
        <div className={styles.block}>
            {props.number!=undefined &&
            <div>There are currently</div>}
            {strNumb.length<4 && props.number!=undefined ?<div className={styles.number}>{strNumb.map(x=><div className={styles.sq}>{x}</div>)}</div>:
                props.number!=undefined &&<div className={styles.number}><div className={styles.sq}>{firstStr}</div><div className={styles.comma}>,</div>{rest.map(x=><div className={styles.sq}>{x}</div>)}</div>}
            <div>NAMs in our database</div>

            </div>
    )
}
export default Widget