import styles from './SearchProvider.module.css'
import Sidebar from "../../../sidebar/sidebar";
import {useSelector} from 'react-redux'
import { MultiSelect } from 'primereact/multiselect';
import axios from 'axios'
import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react';
import blob5 from "../../../../subPanel/5blob.png";
function SearchProvider(){
    const authToken=useSelector((state)=>state.auth.access)
    const [providers,setProviders]=useState([])
    const [selectedProvider,setSelectedProvider]=useState([])
    const [providerBlocks,setProviderBlocks]=useState([])
    
    async function getProviderNames(){
        const request = await axios.get(`${process.env.REACT_APP_DOMAIN}/api/service_provider_names`,{
            headers:{
                'Content-Type':'application/json',
                'Authorization':"Bearer " + String(authToken)
            }
        })
        .then(function(response){
            setProviders(response.data.map((x)=>({"name":x.name,"code":x.name})))
        })
        .catch(function(error){

        })
        .finally(function(){

        })
    }

    async function getProviders(){
      
        const request = await axios.get(`${process.env.REACT_APP_DOMAIN}/api/service_provider_list?name=${selectedProvider.map((x)=>(x.code))}`,{
            headers:{
                'Content-Type':'application/json',
                'Authorization':"Bearer " + String(authToken)
            }
        })
        .then(function(response){
            setProviderBlocks(response.data.results)
            console.log(response.data)
        })
        .catch(function(error){
            console.log(error)
        })
        .finally(function(){

        })
    }

    useEffect(()=>{
        getProviderNames()
        getProviders()
    },[])

    useEffect(()=>{
        getProviders()
    },[selectedProvider])
    return(
        <div>
            
            <div className={styles.block}>
                <Sidebar />
                <div className={styles.menu}>
                <div className={styles.blobHolder}>
						<img src={blob5} className={styles.firstBlob}></img>
						<img src={blob5} className={styles.secondBlob}></img>
						<div className={styles.blobText}>
							<div className={styles.blobTitle}>
								Edit a new Service Provider
							</div>
						</div>
					</div>
                    <MultiSelect placeholder='Select a Provider' className={styles.selector}value={selectedProvider} options={providers} onChange={(e)=>(setSelectedProvider(e.value))} optionLabel="name" display="chip" filter/>
                    <div>
                        {providerBlocks.map(x=><Link to={`/edit_provider/${x.id}`} className={styles.providerBlock}>
                            <div className={styles.name}>
                                <div>{x.name}</div>
                            </div>
                            <div className={styles.created}>
                                    <div style={{'color':'#A6A6A6'}}>Created</div>
                                    <div style={{'color':'#737373','font-weight':'bold'}}>{x.created}</div>
                            </div>
                            <div className={styles.modified}>
                                    <div  style={{'color':'#A6A6A6'}}>Modified</div>
                                    {x.modified!=null?
                                    <div>{x.modified}</div>:
                                    <div style={{'color':'#737373','font-weight':'bold'}}>-</div>
                                    }
                                    
                            </div>
                        </Link>)}
                    </div>
                </div>
                
            </div>
        </div>
    )
}
export default SearchProvider