
import './App.css';
import Navbar from './components/navbar/navbar';
import { Fragment,useState, useEffect } from 'react';

import EditSlug from './components/manage/editMenu/editSlug/editSlug';
import ExpMenu from './components/explore/menu/expMenu';
import Details from './components/explore/details/details';
import Manage from './components/manage/manage';
import EditManage from './components/manage/editManage';
import Login from './components/auth/login';
import DetManage from './components/manage/detManage';
import ManageNews from './components/news/ManageNews';
import Home from './components/news/Home';
import {useSelector,useDispatch} from 'react-redux';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate
} from "react-router-dom";
import jwt_decode from 'jwt-decode'
import axios from 'axios'
import { authActions } from './store/authSlice';
import Register from './components/auth/register';
import ForgotPassword from './components/auth/forgot_password';
import ResetPassword from './components/auth/resetPassword';
import 'bootstrap/dist/css/bootstrap.min.css';

import styles from "./App.module.css"
import { addActions } from './store/addSlice';
import Footer from './components/footer/footer';
import UserNams from './components/myNams/menuBlock';
import Settings from './components/settings/Settings';
import UserInfo from './components/settings/UserInfo';
import FourHundred from './components/auth/404';
import Ciastek from './components/auth/ciastek';
import Accounts from './components/accounts/accounts';
import AccountDetails from './components/accounts/accountDetails';
import SubPanel from './components/subPanel/SubPanel';
import EmailConfirm from './components/auth/emailConfirm';
import Landing from './components/landing/Landing';
import CookiesReg from './components/cookies/cookiesReg';
import About from './components/about/About';
import PatchNotes from './components/news/PatchNotes';
import Pricing from './components/pricing/Pricing';
import GoogleLog from './components/auth/google/googleLog';
import ClientNams from './components/manage/clientNams/ClientNams';
import ServiceProvider from './components/manage/ServiceProvider/newServiceProvider/ServiceProvider';
import EditServiceProvider from './components/manage/ServiceProvider/editServiceProvider/EditServiceProvider';
import SearchProvider from './components/manage/ServiceProvider/editServiceProvider/searchProvider/SearchProvider';
function App() {

  
  const user= useSelector((state) => state.auth.email)
  const isAdmin= useSelector((state) => state.auth.isAdmin)
  let refToken=useSelector((state)=>state.auth.reload)
  

  const dispatch = useDispatch()
  const shopDate=Date.parse(useSelector((state)=>state.env.shopDate))
  const today = new Date()
  dispatch(addActions.setName(null))
  
  

  if (localStorage.getItem("organ") === null) {
    localStorage.setItem('organ', JSON.stringify([]))
  }
  if (localStorage.getItem("regulations") === null) {
    localStorage.setItem('regulations', JSON.stringify([]))
  }
  if (localStorage.getItem("endpoints") === null) {
    localStorage.setItem('endpoints', JSON.stringify([]))
  }
  if (localStorage.getItem("aops") === null) {
    localStorage.setItem('aops', JSON.stringify([]))
  }
  if (localStorage.getItem("stage") === null) {
    localStorage.setItem('stage', JSON.stringify([]))
  }
  if (localStorage.getItem("test") === null) {
    localStorage.setItem('test', JSON.stringify([]))
  }
  if (localStorage.getItem("name") === null) {
    localStorage.setItem('name', JSON.stringify([]))
  }
  if (localStorage.getItem("used") === null) {
    localStorage.setItem('used', JSON.stringify([]))
  }
  if (localStorage.getItem("type") === null) {
    localStorage.setItem('type', JSON.stringify([]))
  }



  if (localStorage.getItem("organEdit") === null) {
    localStorage.setItem('organEdit', JSON.stringify([]))
  }
  if (localStorage.getItem("regulationEdit") === null) {
    localStorage.setItem('regulationsEdit', JSON.stringify([]))
  }
  if (localStorage.getItem("endpointsEdit") === null) {
    localStorage.setItem('endpointsEdit', JSON.stringify([]))
  }
  if (localStorage.getItem("aopsEdit") === null) {
    localStorage.setItem('aopsEdit', JSON.stringify([]))
  }
  if (localStorage.getItem("stageEdit") === null) {
    localStorage.setItem('stageEdit', JSON.stringify([]))
  }
  if (localStorage.getItem("testEdit") === null) {
    localStorage.setItem('testEdit', JSON.stringify([]))
  }
  if (localStorage.getItem("nameEdit") === null) {
    localStorage.setItem('nameEdit', JSON.stringify([]))
  }
  if (localStorage.getItem("usedEdit") === null) {
    localStorage.setItem('usedEdit', JSON.stringify([]))
  }
  if (localStorage.getItem("typeEdit") === null) {
    localStorage.setItem('typeEdit', JSON.stringify([]))
  }


  if (localStorage.getItem("organLabs") === null) {
    localStorage.setItem('organLabs', JSON.stringify([]))
  }
  if (localStorage.getItem("regulationsLabs") === null) {
    localStorage.setItem('regulationsLabs', JSON.stringify([]))
  }
  if (localStorage.getItem("endpointsLabs") === null) {
    localStorage.setItem('endpointsLabs', JSON.stringify([]))
  }
  if (localStorage.getItem("aopsLabs") === null) {
    localStorage.setItem('aopsLabs', JSON.stringify([]))
  }
  if (localStorage.getItem("stageLabs") === null) {
    localStorage.setItem('stageLabs', JSON.stringify([]))
  }
  if (localStorage.getItem("testLabs") === null) {
    localStorage.setItem('testLabs', JSON.stringify([]))
  }
  if (localStorage.getItem("nameLabs") === null) {
    localStorage.setItem('nameLabs', JSON.stringify([]))
  }
  if (localStorage.getItem("usedLabs") === null) {
    localStorage.setItem('usedLabs', JSON.stringify([]))
  }
  if (localStorage.getItem("typeLabs") === null) {
    localStorage.setItem('typeLabs', JSON.stringify([]))
  }



  if (localStorage.getItem("editOrganLabs") === null) {
    localStorage.setItem('editOrganLabs', JSON.stringify([]))
  }
  if (localStorage.getItem("editRegulationsLabs") === null) {
    localStorage.setItem('editRegulationsLabs', JSON.stringify([]))
  }
  if (localStorage.getItem("editEndpointsLabs") === null) {
    localStorage.setItem('editEndpointsLabs', JSON.stringify([]))
  }
  if (localStorage.getItem("editAopsLabs") === null) {
    localStorage.setItem('editAopsLabs', JSON.stringify([]))
  }
  if (localStorage.getItem("editStageLabs") === null) {
    localStorage.setItem('editStageLabs', JSON.stringify([]))
  }
  if (localStorage.getItem("editTestLabs") === null) {
    localStorage.setItem('editTestLabs', JSON.stringify([]))
  }
  if (localStorage.getItem("editNameLabs") === null) {
    localStorage.setItem('editNameLabs', JSON.stringify([]))
  }
  if (localStorage.getItem("editUsedLabs") === null) {
    localStorage.setItem('editUsedLabs', JSON.stringify([]))
  }
  if (localStorage.getItem("editTypeLabs") === null) {
    localStorage.setItem('editTypeLabs', JSON.stringify([]))
  }
 
  
 
  let h_N=useSelector((state)=>state.css.expH_N.payload)
  if (h_N==undefined){
    h_N=1
  }
  let h_F=useSelector((state)=>state.css.expH_F.payload)
  if (h_F==undefined){
    h_F=1
  }
  
  return (
    <Fragment>
      <Ciastek/>
      <Routes>
        <Route path="/login" element={<div className={styles.explore_whole}>
          <div className={styles.homeAddBlock}><div className={styles.homeAdd}><Login/></div><Footer/></div> </div>}></Route>
        <Route path="/register" element={<div className={styles.explore_whole}>
          <div className={styles.homeAddBlock}><div className={styles.homeAdd}><Register/></div><Footer/></div></div>}></Route>
        <Route path="/reset" element={<div className={styles.explore_whole}>
          <div className={styles.homeAddBlock}><div className={styles.homeAdd}><ForgotPassword/></div><Footer/></div></div>}></Route>
        <Route path="/reset_password" element={<div className={styles.explore_whole}>
          <div className={styles.homeAddBlock}><div className={styles.homeAdd}><ResetPassword/></div><Footer/></div></div>}></Route>
     
      
     
        
        <Route path="/explore" element={
          <div className={styles.explore_whole}>
            <Navbar/>
           
            <div className={styles.explore_block}>
                  
            
             {h_N==1 ? h_F==1?
                  <div className={styles.exp_menuFN}> 
                    <ExpMenu/>
                  </div>:
                  <div className={styles.exp_menuN}> 
                    <ExpMenu/>
                  </div>:
              h_F ==1?<div className={styles.exp_menuF}> 
                          <ExpMenu/>
                      </div>:
                       <div className={styles.exp_menu}> 
                          <ExpMenu/>
                      </div>}

              
              <Footer/>
            
          </div>
          
          </div>
          
        }/>
           
        
       {/* <Route path="/details/:slug" element={<div className={styles.explore_whole}>
          <Navbar/>
          
          <div className={styles.details_block}>
            <div className={styles.details_menu}>
              <Details/>
            </div>
            <Footer/>
          </div>
          
        </div>
        }/>*/}

        <Route path="/details/:slug" element={<div className={styles.explore_whole}>
          <Navbar/><div className={styles.homeAddBlock}><div className={styles.homeAdd}><Details/> </div><Footer/></div></div>}/>
         
        <Route path="/add" element={!isAdmin ? <Navigate replace to ="/login"/> :<div className={styles.explore_whole}>
          <Navbar/><div className={styles.manageAddBlock}><div className={styles.manageAdd}><Manage/> </div><Footer/></div></div>}/>

        <Route path="/add_provider" element={!isAdmin ? <Navigate replace to ="/login"/> :<div className={styles.explore_whole}>
          <Navbar/><div className={styles.manageAddBlock}><div className={styles.manageAdd}><ServiceProvider/> </div><Footer/></div></div>}/>

        <Route path="/search_provider" element={!isAdmin ? <Navigate replace to ="/login"/> :<div className={styles.explore_whole}>
          <Navbar/><div className={styles.manageAddBlock}><div className={styles.manageAdd}><SearchProvider/> </div><Footer/></div></div>}/>

        <Route path="/edit_provider/:slug" element={!isAdmin ? <Navigate replace to ="/login"/> :<div className={styles.explore_whole}>
          <Navbar/><div className={styles.manageAddBlock}><div className={styles.manageAdd}><EditServiceProvider/> </div><Footer/></div></div>}/>

        <Route path="/edit" element={!isAdmin ? <Navigate replace to ="/login"/> :<div className={styles.explore_whole}>
          <Navbar/><div className={styles.manageAddBlock}><div className={styles.manageAdd}><EditManage/></div><Footer/></div></div>}/>

        <Route path="/addDetails" element={!isAdmin ? <Navigate replace to ="/login"/> :<div className={styles.explore_whole}> 
          <Navbar/><div className={styles.manageAddBlock}><div className={styles.manageAdd}><DetManage/></div><Footer/></div></div>}/>

        <Route path="/edit/:slug" element={!isAdmin ? <Navigate replace to ="/login"/> :<div className={styles.explore_whole}>
          <Navbar/> <div className={styles.manageAddBlock}><div className={styles.manageAdd}><EditSlug/></div><Footer/></div></div>}/>

        <Route path="/editNews" element={!isAdmin ? <Navigate replace to ="/login"/> :<div className={styles.explore_whole}>
          <Navbar/><div className={styles.homeAddBlock}><div className={styles.homeAdd}><ManageNews/></div><Footer/></div></div>}/>

        <Route path="/" element={<div className={styles.explore_whole}>
          <Navbar/><div className={`${styles.homeAddBlock} ${styles.noPadBot}`}><Landing/></div></div>}/>

        <Route path="/cookie_policy" element={<div className={styles.explore_whole}>
          <Navbar/><div className={styles.homeAddBlock}><CookiesReg/><Footer/></div></div>}/>

        <Route path="/about" element={<div className={styles.explore_whole}>
          <Navbar/><div className={`${styles.homeAddBlock} ${styles.noPadBot}`} ><About/></div></div>}/>

        <Route path="/patch_notes" element={ !user ? <Navigate replace to ="/login"/> :<div className={styles.explore_whole}>
          <Navbar/><div className={styles.homeAddBlock}><PatchNotes/><Footer/></div></div>}/>

        <Route path="/pricing" element={<div className={styles.explore_whole}>
          <Navbar/><div className={styles.homeAddBlock}><Pricing/><Footer/></div></div>}/>
        

        {/*<Route path="/settings" element={!user ? <Navigate replace to ="/login"/> :<div className={styles.explore_whole}>
          <Navbar/><div className={styles.homeAddBlock}><div className={styles.homeAdd}><Settings/></div><Footer/></div></div>}/>*/}

        <Route path="/userInfo" element={!user ? <Navigate replace to ="/login"/> :<div className={styles.explore_whole}>
          <Navbar/><UserInfo/><Footer/></div>}/>
          <Route path="/recomend_nams" element={!user ? <Navigate replace to ="/login"/> :<div className={styles.explore_whole}>
          <Navbar/><div className={styles.homeAddBlock}><ClientNams/><Footer/></div></div>}/>
          <Route path="/subPanel" element={!user ? <Navigate replace to ="/login"/> :<div className={styles.explore_whole}>
          <Navbar/><div className={styles.home_block}><div className={styles.homeAdd}><SubPanel/></div><Footer/></div></div>}/>
        
        <Route path="/accounts" element={!isAdmin ?<Navigate replace to ="/login"/> :<div className={styles.explore_whole}>
          <Navbar/><div className={styles.home_block}><div className={styles.homeAdd}><Accounts/></div><Footer/></div></div>}/>

        <Route path="/accounts/:slug" element={!isAdmin ? <Navigate replace to ="/login"/> :<div className={styles.explore_whole}>
          <Navbar/><div className={styles.home_block}><div className={styles.homeAdd}><AccountDetails/> </div><Footer/></div></div>}/>
        
        <Route path="/confirm_account/:slug" element={<div className={styles.explore_whole}> <Navbar/><div className={styles.home_block}><div className={styles.homeAdd}><EmailConfirm/></div></div></div>}/>
        <Route path="/google" element={<GoogleLog/>}></Route>

        

        <Route path="*" element={<div><FourHundred/><Footer/></div>}/>

        
      
      </Routes>
   </Fragment>
  );
}

export default App;
