import styles from "./ClientNams.module.css";
import FormElement from "./FormElement";
import { useState } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import blob5 from "../../subPanel/5blob.png";
import { LiaQuestionSolid } from "react-icons/lia";
function ClientNams() {
	const authToken = useSelector((state) => state.auth.access);
	const email = useSelector((state) => state.auth.email);

	const [name, setName] = useState("");

	const [organ, setOrgan] = useState("");
	const [namType, setNamType] = useState("");
	const [endpoint, setEndpoint] = useState("");
	const [type, setType] = useState("");
	const [test, setTest] = useState("");
	const [regulations, setRegulations] = useState("");

	const [stage, setStage] = useState("");
	const [applied, setApplied] = useState("");
	const [nanoValid, setNanoValid] = useState("");
	const [aop, setAop] = useState("");
	const [key, setKey] = useState("");
	const [mie, setMie] = useState("");

	const [description, setDescription] = useState("");
    const [descModal,setDescModal]=useState(false)
	const [reference, setReference] = useState("");
    const [refModal,setRefModal]=useState(false)
	const [publications, setPublications] = useState("");
    const [pubModal,setPubModal]=useState(false)

    const [comment, setComment] = useState("");
    const [comModal,setComModal]=useState(false);

    

	const labels1 = [
		{ id: 1, label: "Organ/system:", value: organ, setter: setOrgan, desc:"Please define organ or system (i.e. skin, brain, immune system)" },
		{ id: 2, label: "NAM type:", value: namType, setter: setNamType, desc:"In vitro, in chemico, in silico, hybrid"  },
		{ id: 3, label: "Endpoint:", value: endpoint, setter: setEndpoint, desc:"Provide details on the endpoint type. Should fall within the regulatory relevant type of endpoint, e.g. skin sensitization, acute toxicity by inhalation, carcinogenicity etc." },
		{ id: 4, label: "Document type:", value: type, setter: setType, desc:"Define the legal type of the document, i.e. SOP, publication, OECD Guidance Document." },
		{ id: 5, label: "Guidance/strategy:", value: test, setter: setTest,desc:"If you NAM falls under a specific, validated testing strategy, please provide it’s name/reference (i.e. OECD 401). " },
		{
			id: 6,
			label: "Regulation(s):",
			value: regulations,
			setter: setRegulations,
            desc:"Provide regulation references under which the NAM is applicable. "
		},
	];

	const labels2 = [
		{
			id: 7,
			label: "Stage of NAM development:",
			value: stage,
			setter: setStage,
            desc:"Please indicate the stage of the NAM development. The database currently contains stages as follows: i) nanospecific regulatory accepted; ii) nanospecific under development; iii) nanospecific under validation; iv) non-nanospecific regulatory accepted; v) non-nanospecific under validation. "
		},
		{
			id: 8,
			label: "Applied to ENMs:",
			value: applied,
			setter: setApplied,
            desc:"Please indicate whether this method has been utilized to test nanomaterials."
		},
		{
			id: 9,
			label: "Nano-validated:",
			value: nanoValid,
			setter: setNanoValid,
            desc:"Please state whether the method has been officially validated through legal institutions like OECD to be applicable to nanomaterials."
		},
		{ id: 10, label: "AOP:", value: aop, setter: setAop, desc:"Please provide details if the NAM you are describing is referred to any Adverse Outcome Pathway. AOP-Wiki link is sufficient." },
		{ id: 11, label: "Key Event:", value: key, setter: setKey, desc:"Please provide details if the NAM you are describing is referred to any Key Event. AOP-Wiki link is sufficient." },
		{ id: 12, label: "MIE:", value: mie, setter: setMie, desc:"Please provide details if the NAM you are describing is referred to any Molecular Initiating Event. AOP-Wiki link is sufficient. " },
	];

	async function send_rec() {
		const data = JSON.stringify({
			email: email,
			name: name,
			organ: organ,
			namType: namType,
			endpoint: endpoint,
			type: type,
			test: test,
			regulations: regulations,
			stage: stage,
			applied: applied,
			nanoValid: nanoValid,
			aop: aop,
			key: key,
			mie: mie,
			description: description,
			reference: reference,
			publications: publications,
			comment:comment
		});
		const request = await axios
			.post(
				`${process.env.REACT_APP_DOMAIN}/api/nams_recomendation`,
				data,
				{
					headers: {
						"Content-Type": "application/json",
						Authorization: "Bearer " + String(authToken),
					},
				}
			)
			.then(function (response) {
				window.location.reload();
			})
			.catch(function (error) {})
			.finally(function () {});
	}

	return (
		<div className={styles.menuBlock}>
			<div className={styles.blobHolder}>
				<img src={blob5} className={styles.firstBlob}></img>
				<img src={blob5} className={styles.secondBlob}></img>
				<div className={styles.blobText}>
					<div className={styles.blobTitle}>
						Do you have a recommendation?
					</div>
					<div className={styles.blobSubtitles}>
						<div>
							If you would like to recommend a new NAM to add to
							the database
						</div>
						<div>
							please fill in the details below and our experts
							will verify its eligibility
						</div>
					</div>
				</div>
			</div>
			<div className={styles.scrollSlave}>
				<div className={styles.subtitle}>NAM primary details:</div>

				<div className={styles.mainForm}>
					<div className={styles.firstColumn}>
						<div className={styles.nameRow}>
							<FormElement
								label="Name:"
								value={name}
								setter={setName}
                                desc={"Please provide a concise and informative name of your NAM, which will be visible to all users."}
							/>
						</div>
						{labels1.map((x) => (
							<FormElement
								label={x.label}
								value={x.value}
								setter={x.setter}
                                desc={x.desc}
							/>
						))}
					</div>
					<div className={styles.secondColumn}>
						<div className={styles.filler}>filler</div>
						{labels2.map((x) => (
							<FormElement
								label={x.label}
								value={x.value}
								setter={x.setter}
                                desc={x.desc}
							/>
						))}
					</div>
				</div>

				<div className={styles.subtitle}>Metadata:</div>
				<div className={styles.metaData}>
					<div className={styles.metaEl}>
						<div className={styles.metaRow}>
							<div className={styles.label}>Publications:</div>
							<input
								className={styles.input}
								value={publications}
								onChange={(e) =>
									setPublications(e.target.value)
								}
							></input>
                            <button className={styles.questionButt} onMouseEnter={(e)=>(setPubModal(true))} onMouseLeave={(e)=>(setPubModal(false))}><LiaQuestionSolid className={styles.questionIcon}/></button>
                            {pubModal &&<div className={styles.modal} ><div className={styles.modalText}>If your NAM has been used in the literature several times, provide all DOIs, separated by commas.</div></div>}
						</div>

						<div className={styles.metaRow2}>
							<div className={styles.label}>Reference URL:</div>
							<input
								className={styles.input}
								value={reference}
								onChange={(e) => setReference(e.target.value)}
							></input>
                            <button className={styles.questionButt} onMouseEnter={(e)=>(setRefModal(true))} onMouseLeave={(e)=>(setRefModal(false))}><LiaQuestionSolid className={styles.questionIcon}/></button>
                            {refModal &&<div className={styles.modal}><div className={styles.modalText}>Provide full URL to the method document. If it’s a publication provide DOI.</div></div>}
						</div>
					</div>
					<div className={styles.metaEl}>
						<div className={styles.metaRowDesc}>
							<div className={styles.label}>Description:</div>
							<textarea
								className={styles.textarea}
								value={description}
								onChange={(e) => setDescription(e.target.value)}
							></textarea>
                            <button className={styles.questionButt} onMouseEnter={(e)=>(setDescModal(true))} onMouseLeave={(e)=>(setDescModal(false))}><LiaQuestionSolid className={styles.questionIcon}/></button>
                            {descModal &&<div className={`${styles.modal} ${styles.areaModal}`}><div className={styles.modalText}>Provide full description of the method. Usually a descriptive paragraph. </div></div>}
						</div>
					</div>

                    <div className={styles.metaEl}>
						<div className={styles.metaRowDesc}>
							<div className={styles.label}>Additional commetns:</div>
							<textarea
								className={styles.textarea}
								value={comment}
								onChange={(e) => setComment(e.target.value)}
							></textarea>
                            <button className={styles.questionButt} onMouseEnter={(e)=>(setComModal(true))} onMouseLeave={(e)=>(setComModal(false))}><LiaQuestionSolid className={styles.questionIcon}/></button>
                            {comModal &&<div className={`${styles.modal} ${styles.areaModal}`}><div className={styles.modalText}>Please provide your comments or any additional information that you find relevant.</div></div>}
						</div>
					</div>
                    <div className={styles.metaBreak}></div>
				</div>
				<button className={styles.sendBut} onClick={send_rec}>
					Send
				</button>
				<div className={styles.break}></div>
			</div>
		</div>
	);
}
export default ClientNams;
