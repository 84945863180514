import styles from './EditServiceProvider.module.css'
import Tlo from "../../../landing/blob2.png";
import Sidebar from "../../sidebar/sidebar";
import {useSelector} from 'react-redux'
import { useEffect,useState } from "react";
import axios from 'axios'
import FiltersExpander from "./filter/filterExpander";
import Table from "./table/table";
import blob5 from "../../../subPanel/5blob.png";
import { useParams } from 'react-router-dom';
import { IoIosCheckmarkCircle } from "react-icons/io";
import { IoMdCloseCircle } from "react-icons/io";
function EditServiceProvider(){

	const slug=useParams()
	console.log(slug.slug)

    const authToken=useSelector((state)=>state.auth.access)
	const stages= useSelector((state) => state.editLabsFilter.stages)
    const organs= useSelector((state) => state.editLabsFilter.organs)
  
    const endpoints= useSelector((state) => state.editLabsFilter.endpoints)
    const tests= useSelector((state) => state.editLabsFilter.tests)
    const regulations = useSelector((state)=>state.editLabsFilter.regulations)
    const aops= useSelector((state)=>state.editLabsFilter.aops)
    const used= useSelector((state)=>state.editLabsFilter.used)
    const namType= useSelector((state)=>state.editLabsFilter.namType)
    const name= useSelector((state)=>state.editLabsFilter.name)

	const [selectedId,setSelectedId]=useState([])
    const [selectedNames,setSelectedNames]=useState([])

    const [providerName,setProviderName]=useState('')
    const [providerLocation,setProviderLocation]=useState('')
    const [providerDescription,setProviderDescription]=useState('')
    const [providerUrl,setProviderUrl]=useState('')
	const [country,setCountry]=useState('')
	const [city,setCity]=useState('')
	const [street,setStreet]=useState('')

    const [errorModal,setErrorModal]=useState('')
	const [successModal,setSuccessModal]=useState(false)
    


    async function editProvider(e){
		e.preventDefault()
        const data=JSON.stringify(
			{
				'slug':slug.slug,
                'name':e.target.name.value,
                location:e.target.location.value,
                'description':e.target.description.value,
                'url':e.target.url.value,
				country:e.target.country.value,
			city:e.target.city.value,
			street:e.target.street.value,
				'nams':selectedId
            }
        )
        const request= await axios.put(`${process.env.REACT_APP_DOMAIN}/api/service_provider`,data,{
            headers:{
                'Content-Type':'application/json',
                'Authorization':"Bearer " + String(authToken)
            }
        })
		.then(function(response){
			setSuccessModal(true)
		})
		.catch(function(error){
			setErrorModal(error.response.data)
		})
		.finally(function(){

		})
	
    }

	function confirm(){
		window.location.reload()
	}

    
    useEffect(()=>{
    },[])
	return (
		<div>
			<div className={styles.block}>
			{successModal &&
				<div className={styles.modalBlure}>
					<div className={styles.subModalBox}>
						<div className={styles.modalText}>
							<IoIosCheckmarkCircle className={styles.checkIcon}/>
							<div>Provider has been successfully edited</div>
						</div>
						<button className={styles.okBut} onClick={confirm}>Ok</button>
					</div>
				</div>
			}

			{errorModal!=''&&
				<div className={styles.modalBlure}>
					<div className={styles.subModalBox}>
						<div className={styles.modalText}>
							<IoMdCloseCircle className={styles.errorIcon}/>
							<div>{errorModal}</div>
						</div>
						<button className={styles.errorBut} onClick={(e)=>setErrorModal('')}>Ok</button>
					</div>
				</div>
			}
				<Sidebar />
				<div className={styles.menu}>
					<div className={styles.blobHolder}>
						<img src={blob5} className={styles.firstBlob}></img>
						<img src={blob5} className={styles.secondBlob}></img>
						<div className={styles.blobText}>
							<div className={styles.blobTitle}>
								Edit a Service Provider
							</div>
						</div>
					</div>
					<form onSubmit={editProvider} className={styles.form}>
						<div className={styles.menuBlock}>
							<div className={styles.title}>Company details:</div>
							<div className={styles.background}>
								<div className={styles.compColOne}>
									<div className={styles.mainRow}>
										<div className={styles.label}>
											Name:
										</div>
										<input
													type="text"
													id="name"
													className={styles.input}
													required
													defaultValue={providerName}
												></input>
									</div>
								
									<div className={styles.mainRow}>
									
											<div className={styles.label}>
												Location:
											</div>
											<input
												className={`${styles.input} `}
												id="location"
												required
												defaultValue={providerLocation}
											></input>
								
									
									</div>
									<div className={`${styles.locLabel}`}>
										Adress:
									</div>
									<div className={styles.locationRow}>
										<div className={styles.location}>
											<div className={styles.label}>
												Country:
											</div>
											<input
												className={`${styles.input} ${styles.locInp}`}
												id="country"
												required
												defaultValue={country}
											></input>
										</div>
										<div className={styles.location}>
											<div className={styles.label}>
												City:
											</div>
											<input
												className={`${styles.input} ${styles.locInp}`}
												id="city"
												required
												defaultValue={city}
											></input>
										</div>
										<div className={styles.location}>
											<div className={styles.label}>
												Street:
											</div>
											<input
												className={`${styles.input} ${styles.locInp}`}
												id="street"
												required
												defaultValue={street}
											></input>
										</div>
									</div>
								
										<div className={`${styles.mainRow} ${styles.lastRow}`}>
											<div className={styles.label}>
												Reference URL:
											</div>
										
												<input
													type="text"
													id="url"
													className={styles.input}
													required
													defaultValue={providerUrl}
												></input>
										
										</div>
										
								</div>
								<div className={styles.compColOne}>
								<div className={`${styles.mainRow} ${styles.descRow}`}>
									<div className={styles.label}>
										Description:
									</div>
									
										<textarea
											className={styles.desc}
											type="text"
											id="description"
											required
											defaultValue={providerDescription}
										></textarea>
									
								</div>
								</div>
							</div>
							<div className={styles.selectNamsTitle}>
								<div className={styles.title}>Provider's service scope:</div>
								<div className={styles.subtitle}>Select NAM declared in Provider’s application</div>
							</div>
							<FiltersExpander
								stages={stages}
								endpoints={endpoints}
								tests={tests}
								regulations={regulations}
								aops={aops}
								used={used}
								namType={namType}
								authToken={authToken}
								organs={organs}
								name={name}
							/>
							<Table
								selectedId={selectedId}
								setSelectedId={setSelectedId}
								selectedNames={selectedNames}
								setSelectedNames={setSelectedNames}
								setProviderName={setProviderName}
								setProviderDescription={setProviderDescription}
								setProviderLocation={setProviderLocation}
								setProviderUrl={setProviderUrl}
								setCountry={setCountry}
								setCity={setCity}
								setStreet={setStreet}
							/>
							<button className={styles.sendBut} type='submit'>
								Edit
							</button>
						</div>
					</form>
				</div>
			</div>
		</div>
	);
}
export default EditServiceProvider